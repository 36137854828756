import * as Sentry from '@sentry/browser';

let configureSentry = function (config) {
    if (!config.dsn) {return}

    Sentry.init({
        dsn: config.dsn,
        release: config.release ? `pfotenbilanz@${config.release}` : null,
        environment: config.environment,
        integrations: [new Sentry.BrowserTracing()],
        tracesSampleRate: config.sample_rate,
    });
}

let initGoogleAnalytics = function (config) {
    if (!config.id) {return}

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', config.id, {
        'user_id': config.user_id,
    });
}

window.addEventListener('DOMContentLoaded', () => {
    let config = JSON.parse(document.getElementById('js-telemetry-config').textContent);

    configureSentry(config.sentry);
    initGoogleAnalytics(config.google_analytics);
});
